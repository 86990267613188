import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './components/NavBar/Navbar';
import Home from './components/pages/Home';
import Portfolio from './components/pages/Portfolio';
import Shop from './components/pages/Shop';
import Contact from './components/pages/Contact';
import Nopage from "./components/pages/Nopage";
import Login from "./components/pages/Login";
import Signup from "./components/pages/Signup";

function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navbar/>}>
          <Route path='/' exact element={<Home/>} />
          <Route path='home' exact element={<Home/>} />
          <Route path='portfolio' element={<Portfolio/>} />
          <Route path='shop' element={<Shop/>} />
          <Route path='contact' element={<Contact/>} />
          <Route path='login' element={<Login/>} />
          <Route path='signup' element={<Signup/>} />
          <Route path='' element={<Nopage/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  
  );
}

export default App;
