import React from 'react'

const Contact = () => {
  return (
    <div>
        <br />
        <br />
        <br />
        <br />
      <h1 style={{textAlign:'center'}}>Contact Page</h1>
    </div>
  )
};

export default Contact;

