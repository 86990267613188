import React from 'react'
import './Shop.css'

const Design = () => {
  return (
    <div className='designdiv'>


      <div className='designdiv1'>
    
      </div>



      <div className='designdiv2'>
        <div className='insidedesigndiv2'>
          <img className='imginsidedesigndiv2'  src="logo192.png" alt="" />
          <p>PSD  ₹49</p>
          <button className='bt1'>Buy</button><button>Add to Cart</button>
        </div>

        <div className='insidedesigndiv2'>
          <img className='imginsidedesigndiv2'  src="logo192.png" alt="" />
          <p>PSD  ₹49</p>
          <button className='bt1'>Buy  </button><button>Add to Cart</button>
        </div>

        <div className='insidedesigndiv2'>
          <img className='imginsidedesigndiv2'  src="logo192.png" alt="" />
          <p>PSD  ₹49</p>
          <button className='bt1'>Buy</button><button>Add to Cart</button>
        </div>


        <div className='insidedesigndiv2'>
          <img className='imginsidedesigndiv2'  src="logo192.png" alt="" />
          <p>PSD  ₹49</p>
          <button className='bt1'>Buy</button><button>Add to Cart</button>
        </div>


        <div className='insidedesigndiv2'>
          <img className='imginsidedesigndiv2'  src="logo192.png" alt="" />
          <p>PSD  ₹49</p>
          <button className='bt1'>Buy</button><button>Add to Cart</button>
        </div>


        <div className='insidedesigndiv2'>
          <img className='imginsidedesigndiv2'  src="logo192.png" alt="" />
          <p>PSD  ₹49</p>
          <button className='bt1'>Buy</button><button>Add to Cart</button>
        </div>


        <div className='insidedesigndiv2'>
          <img className='imginsidedesigndiv2'  src="logo192.png" alt="" />
          <p>PSD  ₹49</p>
          <button className='bt1'>Buy</button><button>Add to Cart</button>
        </div>



        <div className='insidedesigndiv2'>
          <img className='imginsidedesigndiv2'  src="logo192.png" alt="" />
          <p>PSD  ₹49</p>
          <button className='bt1'>Buy</button><button>Add to Cart</button>
        </div>


        <div className='insidedesigndiv2'>
          <img className='imginsidedesigndiv2'  src="logo192.png" alt="" />
          <p>PSD  ₹49</p>
          <button className='bt1'>Buy</button><button>Add to Cart</button>
        </div>

        


        <div className='insidedesigndiv2'>
          <img className='imginsidedesigndiv2'  src="logo192.png" alt="" />
          <p>PSD  ₹49</p>
          <button className='bt1'>Buy</button><button>Add to Cart</button>
        </div>


        <div className='insidedesigndiv2'>
          <img className='imginsidedesigndiv2'  src="logo192.png" alt="" />
          <p>PSD  ₹49</p>
          <button className='bt1'>Buy</button><button>Add to Cart</button>
        </div>



        <div className='insidedesigndiv2'>
          <img className='imginsidedesigndiv2'  src="logo192.png" alt="" />
          <p>PSD  ₹49</p>
          <button className='bt1'>Buy</button><button>Add to Cart</button>
        </div>


        <div className='insidedesigndiv2'>
          <img className='imginsidedesigndiv2'  src="logo192.png" alt="" />
          <p>PSD  ₹49</p>
          <button className='bt1'>Buy</button><button>Add to Cart</button>
        </div>


        <div className='insidedesigndiv2'>
          <img className='imginsidedesigndiv2'  src="logo192.png" alt="" />
          <p>PSD  ₹49</p>
          <button className='bt1'>Buy</button><button>Add to Cart</button>
        </div>


        <div className='insidedesigndiv2'>
          <img className='imginsidedesigndiv2'  src="logo192.png" alt="" />
          <p>PSD  ₹49</p>
          <button className='bt1'>Buy</button><button>Add to Cart</button>
        </div>


        <div className='insidedesigndiv2'>
          <img className='imginsidedesigndiv2'  src="logo192.png" alt="" />
          <p>PSD  ₹49</p>
          <button className='bt1'>Buy</button><button>Add to Cart</button>
        </div>


        <div className='insidedesigndiv2'>
          <img className='imginsidedesigndiv2'  src="logo192.png" alt="" />
          <p>PSD  ₹49</p>
          <button className='bt1'>Buy</button><button>Add to Cart</button>
        </div> 
      </div>

    </div>
  )
};

export default Design;
