import React from 'react'

const Portfolio = () => {
  return (
    <main>
      <section className='portfoliosection'>
        <div className='portfoliosectiondiv1'>
          <img src="./logo.svg
          " alt="" />
        </div>
        <div className='portfoliosectiondiv2'></div>
      </section>
      <section></section>
    </main>
  )
};

export default Portfolio;
