import './Login.css';
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {  toast } from "react-toastify";

function Login() {


  const showToastMessage = () => {
    toast.success("Success Notification !", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) return navigate("./home");
  }, [user, loading]);

  return (
    <div className='logindiv' id='logindiv'>
        <form className='logindivform' id='logindivform' action=""> 
          <img className='logoimg1' id='logoimgz' src="./logo.svg" alt="image" />
          {/* <h1 className='logindivformh1' >Login</h1> */}
          {/* <p className='logindivformp'>Welcome to YashGraphics.com</p> */}
          <input className='logininput' type="email" placeholder='Email address' value={email} onChange={(e) => setEmail(e.target.value)}/>
          <input className='logininput'  type="password"  placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)}/>
          <a className='changepass' id='changepass' href="./signup">Forgotten account?</a>
          <input className='btn1' type="submit" value={'LOGIN'}  onClick={() => logInWithEmailAndPassword(email, password)} />
          <a className='createaccount' href="./signup">Don't have an account? signup </a>
        </form> 
    </div>
  );
}

export default Login;


