import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
// import { useHistory } from "react-router-dom";
import {auth, registerWithEmailAndPassword}  from "../../firebase";
import './Signup.css'

function Signup() { 

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  // const history = useHistory();
  const register = () => {
    if (!name) alert("Please enter name");
    registerWithEmailAndPassword(name, email, password);
  };
  useEffect(() => {
    if (loading) return;
    // if (user) history.replace("/dashboard");
  }, [user, loading]);

  return (
    <div className='signupdiv'>
        <form className='signupdivform' id='signupdivform' action=""> 

          <img className='logoimg1' id='logoimgz' src="./logo.svg" alt="image" />

          {/* <h1 className='signupdivformh1'>Signup</h1> */}
          {/* <p className='signupndivformp'>Welcome to YashGraphics.com</p> */}
          <input className='signupinput' type="text" placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />
          <input className='signupinput' type="email" placeholder='Email address'  value={email} onChange={(e) => setEmail(e.target.value)}/>
          <input className='signupinput' type="tel" placeholder='Mobile number' />
          <input className='signupinput' type="password"  placeholder='Password'  value={password} onChange={(e) => setPassword(e.target.value)}/>
          <input className='signupbtn' type="submit" value={'CREATE ACCOUNT'} onClick={register} />
          <a className='loginaccount' href="./login">Already have an account? login </a>
        </form>
    </div>
  );
}

export default Signup
